#product-page {
  background-color: #f9f9f9;
  padding: 20px;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.grid-cols-1 {
  display: grid;
  grid-template-columns: 1fr;
}

.md\:grid-cols-2 {
  grid-template-columns: repeat(2, 1fr);
}

.lg\:grid-cols-3 {
  grid-template-columns: repeat(3, 1fr);
}

.p-6 {
  padding: 1.5rem;
}

.bg-gray-100 {
  background-color: #f7fafc;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.hover\:shadow-xl:hover {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.transition-shadow {
  transition: box-shadow 0.3s ease;
}

.duration-300 {
  transition-duration: 300ms;
}

.bg-blue-600 {
  background-color: #3182ce;
}

.hover\:bg-blue-700:hover {
  background-color: #2b6cb0;
}

.text-white {
  color: #fff;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.hover\:scale-105:hover {
  transform: scale(1.05);
}

.transition-transform {
  transition: transform 0.3s ease;
}

.text-blue-900 {
  color: #2a4365;
}

.uppercase {
  text-transform: uppercase;
}

.font-bold {
  font-weight: 700;
}
