.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination a {
  margin: 0 5px;
  padding: 8px 16px;
  border: 1px solid #ddd;
  color: #007bff;
  cursor: pointer;
  border-radius: 5px; /* Added this line for curved edges */
  transition: background-color 0.3s, color 0.3s;
}

.pagination a:hover {
  background-color: #007bff;
  color: white;
}

.pagination .active a {
  background-color: #007bff;
  color: white;
  border: 1px solid #007bff;
  border-radius: 5px; /* Added this line for curved edges */
}

.break-me {
  cursor: default;
}

.fab {
  margin-right: 5px;
}

/* Add subtle animations for images and buttons */
img {
  transition: transform 0.3s ease-in-out;
}

a:hover img {
  transform: scale(1.05);
}

button {
  transition: background-color 0.3s, color 0.3s;
}

button:hover {
  background-color: #007bff;
  color: white;
}

/* Add a hover effect for the cards */
.card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
